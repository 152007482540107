import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Icons } from '../../../theme';
import { Typography } from '@mui/material';
import Clock from '../clock';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchDrawer from '../searchDrawer';
import EditProfileModal from '../editProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCompanyRequest,
  getUserCompaniesRequest,
  logoutRequest,
} from '../../../redux/slicers/user';
import MemberManagementModal from '../memberManagementModal';
import { CustomDispatch } from '../../../helpers';
import { ClipLoader } from 'react-spinners';
import { Popover, Select, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import NotificationDrawer from '../notificationDrawer';

function PrivateHeader() {
  //STATES
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [memberManagementModalOpen, setMemberManagementModalOpen] =
    useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  //REDUX DATA
  const { data, companies } = useSelector(state => state.user);
  const { isOnline } = useSelector(state => state.offline);

  //CUSTOM DISPATCH
  const [logout, logoutLoader] = CustomDispatch(logoutRequest);
  const [getCompanies, companiesLoader] = CustomDispatch(
    getUserCompaniesRequest,
  );
  const [changeCompany] = CustomDispatch(changeCompanyRequest);

  //CONST VALS
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //HANDLERS
  const searchDrawerOpenHandler = () => {
    setSearchDrawerOpen(!searchDrawerOpen);
  };
  const profileModalOpenHandler = () => {
    setProfileModalOpen(!profileModalOpen);
  };
  const memberManagementModalOpenHandler = () => {
    setMemberManagementModalOpen(!memberManagementModalOpen);
  };
  const handleLogout = () => {
    logout();
    Cookies.remove('access_token');
  };
  const handleCompanyChange = value => {
    console.log(value, 'value');
    changeCompany({ payload: value });
  };

  //HOOKS
  useEffect(() => {
    if (data?.id) getCompanies({ payload: { user_id: data?.id } });
  }, [data]);
  console.log(pathname, 'companies');

  const disableCompanyDropdown =
    pathname === '/' ||
    pathname === '/compliance-rules' ||
    pathname === '/today-submissions';
  return (
    <>
      {isDropdownOpen && (
        <div
          className="fixed w-screen h-screen z-10"
          onClick={() => setIsDropdownOpen(false)}></div>
      )}
      <div
        className={`fixed z-20 right-1 overflow-hidden ${
          isDropdownOpen ? 'top-[73px]' : 'opacity-0 top-[-250px]'
        } border bg-white-100 rounded-md border-blue-300  w-[220px]  transition-top duration-500 ease-in-out `}>

        <div
          className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
          onClick={() => {
            profileModalOpenHandler();
            setIsDropdownOpen(false);
          }}>
          <Icons.ProfileIcon height={16} width={16} />
          <p className="text-[14px]">Profile</p>
        </div>
        {data?.isAdmin && (
          <>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                memberManagementModalOpenHandler();
                setIsDropdownOpen(false);
              }}>
              <Icons.MembersIcon height={16} width={16} />
              <p className="text-[14px]">Member Management</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                navigate('/compliance-rules');
                setIsDropdownOpen(false);
              }}>
              <Icons.NotificationIcon height={16} width={16} />
              <p className="text-[14px]">Notification Settings</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                navigate('/today-submissions');
                setIsDropdownOpen(false);
              }}>
              <Icons.ProcedureIcon height={16} width={16} />
              <p className="text-[14px]">Today's Logs</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                setIsDropdownOpen(false);
              }}>
              <Icons.SettingsIcon height={16} width={16} />
              <p className="text-[14px]">System Settings</p>
            </div>
          </>
        )}
        <div
          className="flex items-center justify-start gap-2 p-4 py-2 hover:bg-[#E2F5FF] cursor-pointer h-[38px]"
          onClick={() => {
            setIsDropdownOpen(false);
            handleLogout();
          }}>
          {logoutLoader ? (
            <div className="flex items-center justify-center w-full ">
              <ClipLoader size={12} color="#004078" />
            </div>
          ) : (
            <>
              <Icons.ExitIcon height={16} width={16} />
              <p className="text-[14px]">Log Out</p>
            </>
          )}
        </div>
        {/* Dropdown content */}
      </div>

      <div
        onClick={() => setIsDropdownOpen(false)}
        className="private-header z-30 flex justify-between gap-4 items-center w-full px-9 py-3">
        <div className="flex items-center	justify-start gap-2">
          <Icons.EberLogo
            className="cursor-pointer"
            onClick={() => navigate('/')}
          />
          <Typography
            className="select-none cursor-pointer"
            fontFamily={'Arial'}
            fontWeight={700}
            fontSize={30}
            onClick={() => navigate('/')}>
            EBER
          </Typography>
          {companies.length > 1 && (
            <Select
              options={companies}
              loading={companiesLoader}
              disabled={companiesLoader || !disableCompanyDropdown}
              defaultValue={data?.companyId}
              onChange={handleCompanyChange}
              // options={[{label: 'Admin', value: 1}]}
              placeholder="Select Clinic"
            />
          )}
        </div>
        {pathname.includes('/form/') && <Clock />}
        <div className="flex items-center	justify-start gap-[30px]">
          {/* <div className="flex items-center justify-start gap-2">
            <div
              className="w-[10px] h-[10px] rounded-full"
              style={{
                backgroundColor: isOnline ? '#00FF00' : '#FF0000',
              }}></div>
            <p className="text-[14px] font-medium">
              {isOnline ? 'Online' : 'Offline'}
            </p>
          </div> */}

          <NotificationDrawer />
          <Icons.SearchIcon
            className="cursor-pointer header-icons"
            height={24}
            width={24}
            onClick={searchDrawerOpenHandler}
          />
          {/* <Icons.ExitIcon
          className="cursor-pointer header-icons"
          height={24}
          width={24}
        /> */}
          <div
            onClick={e => {
              e.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }}
            className="flex items-center justify-start gap-2 hover:text-[#65a6cc] text-[14px] text-blue-300 font-semibold cursor-pointer profile-name">
            <Icons.UserIcon className="cursor-pointer" height={24} width={24} />
            {data?.name}
            <div
              className="w-[10px] h-[10px] rounded-full"
              style={{
                backgroundColor: isOnline ? '#00FF00' : '#FF0000',
              }}></div>
          </div>
          <Popover
            trigger={['click']}
            title="Info"
            placement="bottomRight"
            content={
              <div>
                <p>Version: {process.env.REACT_APP_VERSION}</p>
              </div>
            }
            getPopupContainer={triggerNode => triggerNode.parentNode}>
            <Icons.InfoIcon
              width={20}
              height={20}
              className="cursor-pointer header-icons"
            />
          </Popover>
        </div>
        <SearchDrawer
          open={searchDrawerOpen}
          handleClose={searchDrawerOpenHandler}
        />
        <EditProfileModal
          open={profileModalOpen}
          handleClose={profileModalOpenHandler}
        />
        <MemberManagementModal
          open={memberManagementModalOpen}
          handleClose={memberManagementModalOpenHandler}
        />
      </div>
    </>
  );
}

export default PrivateHeader;
