import React, {useState} from 'react';
import {Select, Modal, Input} from 'antd';
import {CustomDispatch} from '../../../helpers';
import {verifyPinRequest} from '../../../redux/slicers/user';

const OTPSelect = ({
  options,
  loading,
  placeholder,
  onChange,
  value,
  field,
  form,
  disabled,
  isBulk,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [otp, setOtp] = useState('');
  const [tempValue, setTempValue] = useState(null);

  const [verifyPin, verifyPinLoading] = CustomDispatch(verifyPinRequest);

  // Trigger modal and temporarily store the selected value, but do NOT set it yet
  const handleSelectChange = value => {
    // Temporarily reset the form field so that it's not prematurely set
    if (form.getFieldValue(isBulk ? field.label : field.key) === undefined) {
      form.setFieldValue(isBulk ? field?.label : field?.key, undefined);
    }
    setTempValue(value);
    setModalVisible(true);
    console.log(
      form.getFieldValue(isBulk ? field.label : field.key),
      'form.getFieldValue(isBulk ? field.label : field.key)',
    );
  };

  // Handle OTP verification on modal OK
  const handleOk = () => {
    verifyPin({
      payload: {user_id: tempValue, password: otp},
      logic(res) {
        console.log(res, 'OTP Verification');
        if (res.response_code === '200') {
          // Set form value only if OTP is successfully verified
          form.setFieldsValue({[isBulk ? field.label : field.key]: tempValue});
          onChange(tempValue);
        }
        resetModal();
      },
    });
  };

  // Reset the modal and ensure the form value isn't set on cancel
  const handleCancel = () => {
    resetModal();
    if (form.getFieldValue(isBulk ? field.label : field.key) === undefined) {
      form.resetFields([isBulk ? field?.label : field?.key]);
    }
  };

  const resetModal = () => {
    setModalVisible(false);
    setOtp(''); // Clear OTP field
  };

  return (
    <>
      <Select
        showSearch={false}
        getPopupContainer={trigger => trigger.parentNode}
        optionFilterProp="children"
        onSelect={handleSelectChange} // Do not set the value immediately here
        placeholder={placeholder}
        loading={loading}
        value={
          form?.getFieldValue(isBulk ? field?.label : field?.key)?.toString() ||
          undefined
        } // Set only verified value
        disabled={disabled}
        optionLabelProp="label">
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}>
            {option.label}
          </Select.Option>
        ))}
      </Select>

      <Modal
        title="Enter PIN"
        open={modalVisible}
        onOk={handleOk} // Validate OTP before setting value
        confirmLoading={verifyPinLoading}
        okButtonProps={{disabled: otp.length < 1}} // Disable OK button if OTP is not 4 digits
        centered
        onCancel={handleCancel}>
        <Input.Password
          type="text"
          placeholder="Enter PIN"
          value={otp}
          autoFocus
          onPressEnter={handleOk} // Validate OTP on Enter
          onChange={e => setOtp(e.target.value)} // Capture OTP input
        />
      </Modal>
    </>
  );
};

export default OTPSelect;
