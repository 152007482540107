import {EyeOutlined, LockOutlined, SearchOutlined} from '@ant-design/icons'; // Import the search icon
import {Input, Table} from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ConfirmationModal, Loader} from '../../../components';
import {FORM_ROUTE} from '../../../constants';
import {CustomDispatch} from '../../../helpers';
import {
  getTodaysLogsRequest,
  updateTodaysLogRequest,
} from '../../../redux/slicers/form';
import {replaceValInString, toastAlert} from '../../../utils';
import './styles.scss';
import Icons from '../../../theme/icons';

const Submissions = () => {
  //STATES
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  // REDUX DATA
  const {todaysLogs} = useSelector(state => state.form);
  const {data} = useSelector(state => state.user);

  // CUSTOM DISPATCH
  const [getTodaysLogs, isLoading] = CustomDispatch(getTodaysLogsRequest);
  const [updateLogStatus, updateLogStatusLoader] = CustomDispatch(
    updateTodaysLogRequest,
  );

  //CONST VALS
  dayjs.extend(utc);

  dayjs.extend(timezone);

  const navigate = useNavigate();

  let date = new Date().toISOString();

  let updatedDate = dayjs(date)
    .tz(process.env.REACT_APP_TIME_ZONE)
    .format('MMM DD, YYYY');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'form_name',
      render: text => <p className="font-normal">{text}</p>,
      sorter: (a, b) => a.form_name.localeCompare(b.form_name),
    },
    {
      title: 'Submitted By',
      dataIndex: 'submittedBy',
      align: 'center',
      render: text => <p className="font-table">{text ?? '-----'}</p>,
      sorter: (a, b) => a?.submittedBy?.localeCompare(b?.submittedBy),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Action',
      render: (_, record) => (
        <div className="flex items-center justify-start gap-2">
          <EyeOutlined
            className="cursor-pointer"
            onClick={() =>
              navigate(replaceValInString(FORM_ROUTE, {':id': record.form_key}))
            }
          />
          <LockOutlined
            onClick={() => handleEdit(record)}
            className={` ${
              record.status === 'Complete'
                ? 'cursor-pointer'
                : 'opacity-50 cursor-not-allowed'
            }`}
          />
        </div>
      ),
    },
  ];

  const filteredData =
    todaysLogs?.filter(log =>
      log.form_name.toLowerCase().includes(searchQuery.toLowerCase()),
    ) ?? [];

  // HANDLERS
  const handleEdit = record => {
    if (record.status !== 'Complete') return;
    setSelectedRecord(record);
    confirmationModalOpenHandler();
  };

  const confirmationModalOpenHandler = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const handleUpdateLogStatus = () => {
    updateLogStatus({
      pathParams: selectedRecord?.form_key,
      logic() {
        toastAlert(`${selectedRecord?.form_name} status updated to incomplete`);
        getTodaysLogs();
        confirmationModalOpenHandler();
        setSelectedRecord(null);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (data?.id) console.log(data, 'hello');
    console.log(firstLoad);
    getTodaysLogs({
      logic: res => {
        console.log(res, 'hello');
        setFirstLoad(false);
      },
    });
  }, [data]);

  return (
    <section className="submissions mt-[69px]">
      <div className="h-[50px] bg-grey-100 border-b-[1px] border-solid border-grey-200 justify-start flex items-center px-6  gap-[15px]">
        <Icons.LeftChevron
          className="cursor-pointer icon"
          onClick={() => navigate('/')}
        />
        <p className="font-h2 uppercase ">Today's Logs - {updatedDate}</p>
      </div>
      {isLoading && firstLoad ? (
        <Loader height="80vh" />
      ) : (
        <div className="dashboard-content px-[20px] md:px-[40px] py-[20px]">
          {/* <p className="font-arial font-medium text-xl text-textColor-100 mb-[30px]">
            Today's Logs - {updatedDate}
          </p> */}
          <Input
            placeholder="Search by form name"
            prefix={<SearchOutlined />}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="search-input mb-2"
          />
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            className="search-table"
            scroll={{x: 530}}
          />
        </div>
      )}
      <ConfirmationModal
        open={confirmationModalOpen}
        title={'Change Log Status'}
        description={`Are you sure you want to change ${selectedRecord?.form_name} status?`}
        handleClose={() => {
          confirmationModalOpenHandler();
          setSelectedRecord(null);
        }}
        confirmLoader={updateLogStatusLoader}
        onConfirm={handleUpdateLogStatus}
      />
    </section>
  );
};

export default Submissions;
